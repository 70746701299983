// Common

import { AxiosResponse } from 'axios'

export interface GenericErrorData<TStatus> {
  succeeded: false
  errors: {
    status: TStatus
    type?: string
    detail?: string
    instance?: string
    title?: string
    invalidParams?: { errorField: string; errorDescription: string }[]
    internalError?: Record<string, string>
  }
}

export interface BadRequest {
  internalError: ResponseBody
}

export interface ApiOkResponse<T> {
  data: T
  succeeded: true
  status: number
}

export interface ResponseBody {
  registerId: number
  description: Record<string, string>
}

export type ApiErrorResponse = GenericErrorData<400> | GenericErrorData<422> | GenericErrorData<500>

export type ApiResponse<T> = AxiosResponse<ApiOkResponse<T>>

// generics

export interface AmountCurrency {
  currency: string
  amount: number
}

export interface Coverage {
  id: string
  name: string
}

export interface Country {
  code: string
  name: string
}

// api endpoints

export type CurrencyExchange = {
  currencyId: string
  currencyCodeNumber: string
  name: string
  nameEU: string
  exchangeUnit: number
  exchangeValue: number
  billPurchaseValue: number
  billSaleValue: number
  purchaseValue: number
  saleValue: number
}
export type GetCurrencyExchangesResponse = ApiResponse<{ currencyExchanges: CurrencyExchange[] }>

export type GetCustomerStatusResponse = {
  message?: string
  data: {
    succeeded: boolean
    data?: {
      statusCode: string
    }
    errors?: { title: string; invalidParams?: { errorField: string; errorDescription: string }[] }
  }
}

export type GetTravelCountriesResponse = ApiResponse<{ countries: Country[] }>
export type GetTravelCoveragesResponse = ApiResponse<{ coverages: Coverage[] }>

export interface PatchTravelProposalArgs {
  selectedTravelOffer: string
  fullName: string
  email: string
  mobile: string
}
export type PatchTravelProposalResponse = ApiResponse<unknown>

export type PostAppMessageResponse = ApiResponse<{ registerId: string }>

export type PostBolRegistrationCustomerArgs = {
  petitionId: string
  identifier: string
  name: string
  surname: string
  prefix: string
  email: string
  mobile: string
  city: string
  address: string
  postalCode: string
}
export type PostBolRegistrationCustomerResponse = ApiResponse<{ location: string }>

export type PostCustomFormArgs = Record<string, string | boolean>
export type PostCustomFormResponse = ApiResponse<{ registerId: string }>

export type PostInsuranceFuneralQuotaOptions = 'None' | 'CalculateQuota' | 'TakeOut'
export type PostInsuranceFuneralQuotaResponseData = {
  totalByMonth: AmountCurrency
  total: AmountCurrency
}
export type PostInsuranceFuneralQuotaResponse = ApiResponse<PostInsuranceFuneralQuotaResponseData>

export interface PostInsuranceFuneralQuotaArgs {
  postalCode: string
  contracting?: {
    fullName: string
    identifier: string
    email: string
    mobile: string
  }
  policyHolders: { birthDate: string }[]
}

export interface PostLkPayMessageArgs {
  mobile: string
}
export type PostLkPayMessageResponse = ApiResponse<{ registerId: string }>

export interface PostLoanSimulatorArgs {
  goalId: string
  amount: AmountCurrency
  monthToPay: number
  type: 'Monthly' | 'Annual'
}

export interface LoanSimulatorResult {
  fee: number
  tin: number
  tae: number
  amount: AmountCurrency
  openingCommission: number
  openingCommissionAmount: AmountCurrency
  managementCommissionAmount: AmountCurrency
  interestAmount: AmountCurrency
  totalAmount: AmountCurrency
  totalWithOpeningCommissionAmount: AmountCurrency
  url: string
}

export type PostLoanSimulatorResponse = ApiResponse<LoanSimulatorResult>

export interface PostCirculatingSimulatorArgs {
  amount: AmountCurrency
  daysToPay: number
}
export interface InterestComposition {
  euribor: number
  differential: number
}
export interface CirculatingSimulatorResult {
  tin: number
  tae: number
  interestComposition: InterestComposition
  amount: AmountCurrency
  interestAmount: AmountCurrency
  totalAmount: AmountCurrency
  url: string
}

export type PostCirculatingSimulatorResponse = ApiResponse<CirculatingSimulatorResult>

export interface PostRecoverPassCustomerArgs {
  identifier: string
  name: string
  surname: string
  email: string
  prefix: string
  mobile: string
  city: string
  address: string
  postalCode: string
}
export type PostRecoverPassCustomerResponse = ApiResponse<{ location: string }>

export type PostRegisterCustomerArgs = {
  identifier: string
  name: string
  surname: string
  email: string
  prefix: string
  mobile: string
  cardNumber: string
  cvv2: string
  expirationDate: string
  isCardAvailable: boolean
}
export type PostRegisterCustomerResponse = ApiResponse<{
  registerId: string
  description: string
}>

export interface PostTravelProposalArgs {
  startDate: Date
  endDate: Date
  countryCode: string
  travelerCount: number
  coverageCode: string
}

export interface TravelOffer {
  id: string
  definition: string
  properties: string[]
  amount: AmountCurrency
}
export type PostTravelProposalResponse = ApiResponse<{
  travelProposalID: string
  travelOffers: TravelOffer[]
}>

export interface PostPinArgs {
  identifier: string
}

export type PostPinResponse = ApiResponse<{
  petitionId: string
}>

export interface PatchPinArgs {
  petitionId: string
  otpCode?: string
  cardRegisterCustomerData?: {
    name: string
    surname: string
    email: string
    cardNumber: string
    cvv2: string
    expirationDate: string
  }
}

export enum PatchPinOption {
  none = 'None',
  validateOtp = 'ValidateOtp',
  signupWithCard = 'SignupWithCard',
}

export enum PinStatusCodes {
  LessThanAge14 = 0,
  BetweenAge14and17 = 1,
  NoCardNoBol = 2,
  CardNoBol = 3,
  NoCardWithBol = 4,
  CardWithBol = 5,
  FinishBlockedByOffice = 6,
}

export type PatchPinResponse = ApiResponse<{
  statusCode: number
}>

export interface PostInformativeMailArgs {
  email: string
  emailId: 'RecoveryKeyInfo'
}

export type PostInformativeMailResponse = ApiResponse<{
  statusCode: number
}>

export type CashPoint = {
  placeId: string
  formattedAddress: string
  name: string
  code: string
  isLK: boolean
  geometry: {
    location: {
      lat: string
      lng: string
    }
  }
}
export type GetCashPointLocationsResponse = AxiosResponse<{
  officeList: CashPoint[]
}>

export interface PatchRecoverPinWithCc {
  petitionId: string
  cardNumber: string
  cvv2: string
  expirationDate: string
  name: string
  surname: string
}

export type PatchRecoverPinWithCcResponse = ApiResponse<boolean>

export type PostInsuranceAccidentQuota = {
  age: number
}

export type InsuranceAccidentQuotaCobertures = {
  description: string
  import: string
}

export type InsuranceAccidentQuotaModules = {
  moduleCode: number
  moduleImport: {
    currency: string
    amount: number
  }
  cobertures: InsuranceAccidentQuotaCobertures[]
  anualQuota: {
    currency: string
    amount: number
  }
}

export type InsuranceAccidentQuotaProfessions = {
  code: number
  description: string
  tarificable: boolean
}

export type PostInsuranceAccidentQuotaResponse = ApiResponse<{
  petitionId: string
  modules: InsuranceAccidentQuotaModules[]
  professions: InsuranceAccidentQuotaProfessions[]
}>

export type PatchInsuranceAccidentQuotaOptions = 'None' | 'GenerateBudgets' | 'ContractOption'

export type PatchInsuranceAccidentQuotaCustomer = {
  email: string
  name: string
  surname: string
  mobileNumber: string
}

export type PatchInsuranceAccidentQuotaArgs = {
  petitionId?: string
  moduleCode: number
  professionCode?: number
  includeDecease: boolean
  selectedOfferData?: {
    selectedModuleCode: number
    selectedModuleImport: number
    selectedModuleQuota: number
  }
  customerContractingData?: PatchInsuranceAccidentQuotaCustomer
}

export type PatchInsuranceAccidentQuotaBudget = {
  budgetCode: number
  budgetedModule: InsuranceAccidentQuotaModules
  superiorBudgetedModule: InsuranceAccidentQuotaModules
}

export type PatchInsuranceAccidentQuotaResponse = ApiResponse<PatchInsuranceAccidentQuotaBudget>
