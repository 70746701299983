import './AccessibleCarousel.scss'

import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface AccesibleCarouselProps {
  id: string
  autoSlideSeconds: number
  children: React.ReactElement | React.ReactElement[]
  role: string
}

const AccessibleCarousel: React.FC<AccesibleCarouselProps> = ({
  id,
  autoSlideSeconds,
  children,
  role,
}: AccesibleCarouselProps) => {
  const { t } = useTranslation()
  const [autoPlayOn, setAutoPlayOn] = useState(true)
  const [slides, setSlides] = useState<React.ReactElement[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [maxIndex, setMaxIndex] = useState(0)

  const handleNext = useCallback(() => {
    let next = currentIndex + 1
    if (next > maxIndex) {
      next = 0
    }
    setCurrentIndex(next)
  }, [currentIndex, maxIndex])

  const handlePrev = useCallback(() => {
    let prev = currentIndex - 1
    if (prev < 0) {
      prev = maxIndex
    }
    setCurrentIndex(prev)
  }, [currentIndex, maxIndex])

  const items: React.ReactElement[] = useMemo(
    () =>
      (Array.isArray(children) ? [...children] : [children]).map((i, index) => ({
        ...i,
        key: i.props.id || `${id}-slide-${index}`,
      })),
    [children, id],
  )

  useEffect(() => setMaxIndex(items.length - 1), [items])
  useEffect(() => {
    if (!items[currentIndex]) {
      return () => null
    }

    const slide = items[currentIndex]
    setSlides([slide])
    const isVideo = slide.props?.slide?.bannerType === 'text-video' || false
    if (autoPlayOn && autoSlideSeconds && !isVideo) {
      const timer = setTimeout(() => handleNext(), autoSlideSeconds * 1000)
      return () => timer && clearTimeout(timer)
    }
    return () => null
  }, [currentIndex, items, handleNext, autoSlideSeconds, autoPlayOn])

  const handleVideoEnded = useCallback(() => {
    if (!autoPlayOn) {
      return
    }
    handleNext()
  }, [autoPlayOn, handleNext])

  return (
    (!slides && <></>) || (
      <div className="AccessibleCarousel" role={role}>
        <div className="slideContainer" id={`${id}-slides`}>
          {items.map((element, index) => (
            <div
              key={`carouselItem-${id}-${element.key}`}
              className={classNames('carouselItem', {
                carouselItemActive: currentIndex === index,
                carouselItemInactive: currentIndex !== index,
              })}
            >
              {React.cloneElement(element, { onVideoEnded: handleVideoEnded })}
            </div>
          ))}
        </div>
        {items.length > 1 && (
          <div className="bullets">
            <button
              type="button"
              onClick={() => handlePrev()}
              aria-label={t('accessibleCarousel.prev')}
              aria-controls={`${id}-slides`}
              className="prev"
            >
              <span className={classNames('icon')} />
            </button>
            <button
              type="button"
              onClick={() => setAutoPlayOn(!autoPlayOn)}
              aria-label={t(autoPlayOn ? 'accessibleCarousel.stopAutoplay' : 'accessibleCarousel.startAutoplay')}
              aria-controls={`${id}-slides`}
              className="auto-play"
            >
              <span className={classNames('icon', { active: autoPlayOn })} />
            </button>
            <button
              type="button"
              onClick={() => handleNext()}
              aria-label={t('accessibleCarousel.next')}
              aria-controls={`${id}-slides`}
              className="next"
            >
              <span className={classNames('icon')} />
            </button>
          </div>
        )}
      </div>
    )
  )
}

export default AccessibleCarousel
